import React from "react";
import Header from "../../common/Header";
import Hero from "./Hero";
import AboutUs from "./AboutUs";
import Vision from "./Vision";
import Mission from "./Mission";
import Features from "./Features";
import RoadMap from "./RoadMap";
import Footer from "../../common/Footer";
import bottomright from "./../../assets/png/Line.png";
function HomePage() {
  return (
    <>
      <div className="overflow-x-hidden ">
        <div className="hero-bg">
          <Header />
          <Hero />
        </div>
        <AboutUs />
        <div className="middle-bg">
          <Vision />
          <Mission />
          <Features />
        </div>
        <div className="relative z-20">
          <RoadMap />
          <Footer />
          <div className="absolute right-0 bottom-0 z-0 max-w-[800px]">
            <img src={bottomright} alt="img" className="w-full" />
          </div>{" "}
          <div className="absolute left-0-0 bottom-0 z-0 max-w-[800px]">
            <img src={bottomright} alt="img" className="w-full" />
          </div>
        </div>
      </div>
    </>
  );
}

export default HomePage;
