import React from "react";
import { ArrowIcon } from "../../helper/icon";

function Hero() {
  return (
    <>
      <div
        id="home"
        className="max-w-[1500px] mx-auto px-4 sm:px-6 lg:px-8 xl:px-10 2xl:px-12"
      >
        <div className="    mt-8 md:mt-16 lg:mt-20 xl:mt-24 2xl:mt-28 flex flex-col justify-between">
          <div className="max-w-[1110px] mx-auto flex flex-col justify-center items-center">
            <span
              data-aos="fade-up"
              data-aos-easing="linear"
              data-aos-duration="500"
              className="border border-[rgba(141,58,249,0.80)] py-2 sm:py-3 lg:py-4 px-5 lg:px-8 text-sm sm:text-base lg:text-lg text-[#C090FF] uppercase rounded-full tracking-[2px] lg:tracking-[3px]"
            >
              Many possibilities
            </span>
            <h1
              data-aos="fade-up"
              data-aos-easing="linear"
              data-aos-duration="1000"
              className="text-4xl md:text-5xl lg:text-6xl xl:text-7xl onest leading-[120%] sm:leading-[135%] capitalize text-center font-bold my-5 lg:my-7 xl:my-10 heading"
            >
              Bridging Financial Solutions for a Decentralized Future
            </h1>
            <p
              data-aos="fade-up"
              data-aos-easing="linear"
              data-aos-duration="1500"
              className="text-base md:text-lg leading-[150%] text-center text-[#C2BACB] max-w-[660px] mx-auto"
            >
              Keybridge Finance connects traditional finance with the
              decentralized future, offering secure, user-friendly tools to
              manage your assets.
            </p>
            <div className="flex justify-center gap-4 sm:gap-6 mt-5 md:mt-7 lg:mt-10">
              <button
                className="bg-primary rounded-full py-2 md:py-2.5 lg:py-3 xl:py-3.5 px-4 md:px-5 xl:px-6 2xl:px-8 2xl:py-4 text-base lg:text-lg text-white transition-all duration-300 ease-in-out hover:shadow-[0px_0px_20px_1px] hover:shadow-primary border border-transparent "
                data-aos="fade-right"
                data-aos-easing="linear"
                data-aos-duration="2000"
              >
                Launch App
              </button>
              <button
                className="bg-transparent border border-[#7A49FF] rounded-full py-2 md:py-2.5 lg:py-3 xl:py-3.5 px-4 md:px-5 xl:px-6 2xl:px-8 2xl:py-4 text-base lg:text-lg text-[#C2BACB] transition-all duration-300 ease-in-out hover:shadow-[0px_0px_20px_1px] hover:shadow-primary xl:mr-4  hover:text-primary"
                data-aos="fade-left"
                data-aos-easing="linear"
                data-aos-duration="2000"
              >
                Learn More
              </button>
            </div>
          </div>
          <div
            className="max-w-[960px] mx-auto layer-box p-1 md:p-2 lg:p-3 w-full rounded-[20px] mt-12 lg:mt-20  xl:mt-28 2xl:40"
            data-aos="zoom-in"
            data-aos-easing="linear"
            data-aos-duration="500"
          >
            <div className="layer2 rounded-[20px] px-3 sm:px-5 md:px-7 lg:px-8 pt-6 md:pt-8 lg:pt-10 xl:pt-12">
              <div className="layer-3  flex flex-row gap-3 justify-between items-center px-2 sm:px-6 lg:px-8 xl:px-12 2xl:px-16 pb-4 md:pb-6 lg:pb-8 xl:pb-10 rounded-[20px] sm:pt-4 md:pt-6 xl:pt-8">
                <div className="max-w-fit sm:max-w-[220px]">
                  <p className="poppins text-[12px] sm:text-sm md:text-base text-white font-medium">
                    AUM
                  </p>
                  <p className="onest text-base sm:text-lg md:text-xl lg:text-2xl xl:text-3xl text-white font-medium my-1 md:my-2 xl:my-3  ">
                    $1.8m+
                  </p>{" "}
                  <p className="poppins text-[12px] sm:text-sm md:text-base text-[#C2BACB] font-medium">
                    Assets Under Management
                  </p>
                </div>
                <div
                  className="max-w-fit"
                  data-aos="zoom-out"
                  data-aos-easing="linear"
                  data-aos-duration="2000"
                >
                  <ArrowIcon />
                </div>
                <div className="max-w-fit sm:max-w-[200px] text-end">
                  <p className="onest text-base sm:text-lg md:text-xl lg:text-2xl xl:text-3xl text-white font-medium my-1 md:my-2 xl:my-3  ">
                    Coming Soon
                  </p>{" "}
                  <p className="poppins text-[12px] sm:text-sm md:text-base text-[#C2BACB] font-medium">
                    Highest Market APY
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Hero;
