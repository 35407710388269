import React from "react";
import missionimg from "./../../assets/png/mission.png";
function Mission() {
  return (
    <>
      <div className="max-w-[1400px] mx-auto px-4 sm:px-6 lg:px-8 xl:px-10 2xl:px-12">
        <div className="my-10 md:my-14 lg:my-16 xl:mt-24">
          <div className="flex sm:flex-row flex-col-reverse gap-6 justify-between items-center">
            <div
              data-aos="fade-right"
              data-aos-easing="linear"
              data-aos-duration="2000"
              className="max-w-[300px] sm:max-w-[530px]"
            >
              <img
                src={missionimg}
                alt="vision img"
                className="w-full h-auto"
              />
            </div>
            <div className="max-w-[706px] sm:text-end">
              <h2
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration="500"
                className="text-3xl md:text-4xl lg:text-5xl xl:text-6xl onest leading-[120%] sm:leading-[135%] capitalize font-bold my-3 lg:my-6   heading w-fit sm:ml-auto"
              >
                Mission
              </h2>{" "}
              <p
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration="1500"
                className="text-base md:text-lg  leading-[160%] text-[#C2BACB]"
              >
                To democratize access to decentralized finance by providing a
                platform that enhances financial inclusion and allows users to
                earn through innovative DeFi tools such as staking and yield
                farming. Keybridge Finance aims to create a trusted,
                user-friendly ecosystem that connects traditional finance with
                the efficiency and transparency of blockchain technology, while
                enabling seamless interoperability across multiple blockchain
                networks to foster a more integrated and decentralized financial
                landscape.
              </p>{" "}
              <button
                className="bg-transparent border border-[#7A49FF] rounded-full py-2 md:py-2.5 lg:py-3 xl:py-3.5 px-4 md:px-5 xl:px-6 2xl:px-8 2xl:py-4 text-base lg:text-lg text-[#C2BACB] transition-all duration-300 ease-in-out hover:shadow-[0px_0px_20px_1px] hover:shadow-primary xl:mr-4  hover:text-primary mt-4 md:mt-6 xl:mt-9"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration="2000"
              >
                Learn More
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Mission;
