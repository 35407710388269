import React from "react";
import { CrossIcon } from "../helper/icon";
import { NavlinkData } from "../helper/Helper";

const Sidebar = ({ toggleOpen }) => {
  return (
    <>
      <div className="h-screen w-screen lg:hidden flex items-center justify-center flex-col relative">
        <button className="absolute top-10 right-4" onClick={toggleOpen}>
          <CrossIcon />
        </button>

        <div className="flex flex-col gap-5 items-center">
          {NavlinkData.map((item, index) => (
            <a
              key={index}
              href={item.url}
              onClick={toggleOpen}
              className="text-[#96969F] text-base transition-all duration-300 ease-in-out hover:text-[#C090FF]"
            >
              {item.title}
            </a>
          ))}
          <button className="bg-primary rounded-full py-2 md:py-2.5 lg:py-3 xl:py-3.5 px-4 md:px-5 xl:px-6 text-base lg:text-lg text-white ">
            Launch App
          </button>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
