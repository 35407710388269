import React from "react";
import phoneimg from "./../../assets/png/phone.webp";
import q1 from "./../../assets/svg/q1.svg";
import q2 from "./../../assets/svg/q2.svg";
import q3 from "./../../assets/svg/q3.svg";
import q4 from "./../../assets/svg/q4.svg";
function RoadMap() {
  return (
    <>
      <div id="Roadmap" className="roadmap-bg">
        <div className="max-w-[1400px] mx-auto px-4 sm:px-6 lg:px-8 xl:px-10 2xl:px-12">
          <div className="py-10 md:py-14 lg:py-16 xl:pt-24">
            <h2
              data-aos="fade-up"
              data-aos-easing="linear"
              data-aos-duration="500"
              className="text-3xl md:text-4xl lg:text-5xl xl:text-6xl onest leading-[120%] sm:leading-[135%] capitalize font-bold my-3 lg:my-6   heading w-fit mx-auto"
            >
              Roadmap
            </h2>
            <div className="flex flex-col lg:flex-row justify-between mt-6 md:mt-8 xl:mt-14 max-w-[1024px] w-full mx-auto gap-y-6 md:gap-y-8">
              <div
                data-aos="fade-right"
                data-aos-easing="linear"
                data-aos-duration="1000"
                className="border card-bg py-2 md:py-3 xl:py-3.5 px-4 md:px-5 xl:px-6  lg:max-w-[404px] w-full relative"
              >
                <div className="absolute right-[-65px] top-[100px]  hidden lg:block">
                  <img src={q1} alt="img" />
                </div>
                <h4 className="text-white uppercase text-xl xl:text-3xl font-bold">
                  2025 - Q1
                </h4>
                <p className="text-lg xl:text-[22px] text-white capitalize my-1 xl:my-2  ">
                  Platform Foundation
                </p>{" "}
                <p className="text-sm xl:text-base leading-[165%] text-[#ADAAC6]  ">
                  Completion of the core architecture for Keybridge Finance,
                  ensuring a scalable and secure platform for DeFi solutions.
                </p>{" "}
                <p className="text-sm xl:text-base leading-[165%] text-[#ADAAC6] mt-2 lg:mt-3 2xl:mt-4  ">
                  Completion of the core architecture for Keybridge Finance,
                  ensuring a scalable and secure platform for DeFi solutions.
                </p>
              </div>
              <div
                data-aos="fade-left"
                data-aos-easing="linear"
                data-aos-duration="1000"
                className="border card-bg py-2 md:py-3 xl:py-3.5 px-4 md:px-5 xl:px-6 lg:max-w-[404px] w-full relative flex flex-col justify-center  "
              >
                <div className="absolute left-[-65px] top-[100px]  hidden lg:block">
                  <img src={q2} alt="img" />
                </div>
                <h4 className="text-white uppercase text-xl xl:text-3xl font-bold">
                  2024 - Q2
                </h4>
                <p className="text-lg xl:text-[22px] text-white capitalize my-1 xl:my-2  ">
                  Staking & Governance
                </p>{" "}
                <p className="text-sm xl:text-base leading-[165%] text-[#ADAAC6]  ">
                  Launch staking platform for token holders to earn rewards.
                </p>{" "}
                <p className="text-sm xl:text-base leading-[165%] text-[#ADAAC6] mt-2 lg:mt-3 2xl:mt-4  ">
                  Enable decentralized governance, allowing users to vote on
                  platform decisions.
                </p>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row justify-between mt-6 md:mt-8 xl:mt-14 max-w-[1174px] w-full mx-auto gap-y-6 md:gap-y-8">
              <div
                data-aos="fade-right"
                data-aos-easing="linear"
                data-aos-duration="2000"
                className="border card-bg py-2 md:py-3 xl:py-3.5 px-4 md:px-5 xl:px-6 lg:max-w-[404px] w-full relative"
              >
                <div className="absolute right-[-65px] top-[100px] hidden lg:block">
                  <img src={q3} alt="img" />
                </div>
                <h4 className="text-white uppercase text-xl xl:text-3xl font-bold">
                  2025 - Q3
                </h4>
                <p className="text-lg xl:text-[22px] text-white capitalize my-1 xl:my-2  ">
                  Growth & Partnerships
                </p>{" "}
                <p className="text-sm xl:text-base leading-[165%] text-[#ADAAC6]  ">
                  Form strategic partnerships with other DeFi projects.
                </p>{" "}
                <p className="text-sm xl:text-base leading-[165%] text-[#ADAAC6] mt-2 lg:mt-3 2xl:mt-4  ">
                  Introduce yield farming opportunities for users to earn more
                  rewards.
                </p>
              </div>
              <div
                data-aos="fade-left"
                data-aos-easing="linear"
                data-aos-duration="2000"
                className="border card-bg py-2 md:py-3 xl:py-3.5 px-4 md:px-5 xl:px-6 lg:max-w-[404px] w-full relative flex flex-col justify-center "
              >
                {" "}
                <div className="absolute left-[-65px] top-[100px]  hidden lg:block">
                  <img src={q4} alt="img" />
                </div>
                <h4 className="text-white uppercase text-xl xl:text-3xl font-bold">
                  2025 - Q4
                </h4>
                <p className="text-lg xl:text-[22px] text-white capitalize my-1 xl:my-2  ">
                  Financial Products & Community
                </p>{" "}
                <p className="text-sm xl:text-base leading-[165%] text-[#ADAAC6]  ">
                  Roll out advanced financial tools like lending and borrowing.
                </p>
                <p className="text-sm xl:text-base leading-[165%] text-[#ADAAC6] mt-2 lg:mt-3 2xl:mt-4  ">
                  Engage the community through reward programs and referral
                  incentives.
                </p>
              </div>
            </div>
            <div className="max-w-[300px] sm:max-w-[500px] lg:max-w-[750px] mx-auto lg:-mt-60 xl:-mt-80">
              <img src={phoneimg} alt="img" className="w-full h-auto" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RoadMap;
