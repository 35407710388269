export const NavlinkData = [
  {
    title: "Home",
    url: "#home",
  },
  {
    title: "About Us",
    url: "#About_Us",
  },
  {
    title: "How It Works",
    url: "#How_It_Works",
  },
  {
    title: "Roadmap",
    url: "#Roadmap",
  },
];
export const mediaLinkData = [
  {
    title: "Telegram",
    url: "https://web.telegram.org",
  },
  {
    title: "Discord",
    url: "/https://discord.com/",
  },
  {
    title: "Twitter",
    url: "https://x.com",
  },
];
