import React from "react";
import featuresimg from "./../../assets/png/features.png";
import boximg from "./../../assets/png/box.png";
function Features() {
  return (
    <>
      <div
        id="How_It_Works"
        className="max-w-[1400px] mx-auto px-4 sm:px-6 lg:px-8 xl:px-10 2xl:px-12"
      >
        <div className="py-10 md:py-14 lg:py-16 xl:pb-24">
          <div className="flex sm:flex-row flex-col gap-6 justify-between items-center">
            <div className="max-w-[680px]">
              <div className="xl:pb-4">
                <span
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration="500"
                  className="work-bg  text-[#C2BACB] text-sm md:text-base py-2 md:py-2.5 px-4 md:px-6 lg:px-8 rounded-full "
                >
                  HOW IT WORKS
                </span>
              </div>
              <h2
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration="1000"
                className="text-3xl md:text-4xl lg:text-5xl xl:text-6xl onest leading-[120%] sm:leading-[135%] capitalize font-bold my-3 lg:my-6   heading w-fit"
              >
                Features
              </h2>
              <p
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration="1500"
                className="text-base md:text-lg  leading-[160%] text-[#C2BACB]"
              >
                Keybridge Finance offers a variety of decentralized financial
                tools, including staking for passive income, yield farming for
                increased rewards, and a cross-chain bridge for seamless asset
                transfers between blockchains. The platform supports
                decentralized governance, enabling users to vote on important
                decisions, and provides advanced financial services like lending
                and borrowing. Keybridge is designed with security and
                scalability at its core, offering a reliable DeFi experience
                with low transaction fees and strict regulatory compliance.
              </p>{" "}
              <div
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration="2000"
                className="flex  gap-4 sm:gap-6 mt-5 md:mt-7 lg:mt-10"
              >
                <button className="bg-primary rounded-full py-2 md:py-2.5 lg:py-3 xl:py-3.5 px-4 md:px-5 xl:px-6 2xl:px-8 2xl:py-4 text-base lg:text-lg text-white transition-all duration-300 ease-in-out hover:shadow-[0px_0px_20px_1px] hover:shadow-primary border border-transparent ">
                  Launch App
                </button>
                <button className="bg-transparent border border-[#7A49FF] rounded-full py-2 md:py-2.5 lg:py-3 xl:py-3.5 px-4 md:px-5 xl:px-6 2xl:px-8 2xl:py-4 text-base lg:text-lg text-[#C2BACB] transition-all duration-300 ease-in-out hover:shadow-[0px_0px_20px_1px] hover:shadow-primary xl:mr-4  hover:text-primary">
                  Learn More
                </button>
              </div>
            </div>
            <div
              data-aos="fade-left"
              data-aos-easing="linear"
              data-aos-duration="2000"
              className="max-w-[300px] sm:max-w-[492px] relative  "
            >
              <div className="absolute bottom-0 right-0 max-w-[400px] z-10">
                <img src={boximg} alt="img" className="w-full" />
              </div>
              <img
                src={featuresimg}
                alt="vision img"
                className="w-full h-auto"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Features;
