import React from "react";
import { mediaLinkData, NavlinkData } from "../helper/Helper";
import { LogoIcon, TopIcon } from "../helper/icon";

function Footer() {
  return (
    <>
      <div className="-mt-8 sm:-mt-16 lg:-mt-20 bg-black  ">
        <div className="bg-gradient h-[2px]"></div>
        <div className="max-w-[1400px] mx-auto px-4 sm:px-6 lg:px-8 xl:px-10 2xl:px-12 bg-black">
          <div className="py-10 md:pt-14 xl:py-20  2xl:py-24 relative z-20">
            <div className="flex flex-wrap lg:flex-nowrap justify-center lg:justify-between items-center w-full gap-5 ">
              <div className="flex flex-col  gap-2 w-full lg:max-w-[456px] order-3 lg:order-1">
                <div className="flex gap-3 sm:gap-4 xl:gap-6 justify-center lg:justify-start">
                  {NavlinkData.map((item, index) => (
                    <a
                      key={index}
                      href={item.url}
                      className="text-[#ADAAC6] text-base xl:text-xl transition-all duration-300 ease-in-out hover:text-[#C090FF] abeezee"
                    >
                      {item.title}
                    </a>
                  ))}
                </div>
                <p className="text-sm lg:text-base xl:text-lg text-[#BBB] text-center lg:text-start">
                  © 2024. All rights reserved | secure
                </p>
              </div>
              <div className="max-w-20   lg:ml-20 order-1 lg:order-2">
                <a href="/">
                  <LogoIcon />
                </a>
              </div>
              <div className="w-full lg:max-w-[480px]   flex justify-center lg:justify-start gap-1 sm:gap-3  lg:gap-6 order-2 lg:order-3">
                {mediaLinkData.map((item, index) => (
                  <a
                    key={index}
                    href={item.url}
                    target="_blank"
                    className="flex items-center text-[#ADAAC6] text-sm  sm:text-base xl:text-lg transition-all duration-300 ease-in-out hover:text-primary audiowide uppercase"
                  >
                    {item.title} <TopIcon />
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
