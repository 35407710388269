import React, { useEffect, useState } from "react";
import { HamburgerIcon, LogoIcon } from "../helper/icon";
import { NavlinkData } from "../helper/Helper";
import Sidebar from "./Sidebar";
// import logo from "./../assets/svg/logo.svg"
function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const body = document.body;
    const html = document.documentElement;
    if (isOpen) {
      body.style.overflow = "hidden";
      html.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
      html.style.overflow = "auto";
    }
    return () => {
      body.style.overflow = "auto";
      html.style.overflow = "auto";
    };
  }, [isOpen]);
  return (
    <>
      <div className="max-w-[1500px] mx-auto px-4 sm:px-6 lg:px-8 xl:px-10 2xl:px-12">
        <div className="py-6 lg:py-8">
          <nav className="flex justify-between items-center">
            <a href="#" className="logo max-w-11 w-full">
              <LogoIcon />
            </a>
            <div className="  flex-row gap-3 md:gap-5 lg:gap-7 xl:gap-9 2xl:gap-12 items-center hidden md:flex">
              {NavlinkData.map((item, index) => (
                <a
                  key={index}
                  href={item.url}
                  className="text-[#96969F] text-base transition-all duration-300 ease-in-out hover:text-[#C090FF]"
                >
                  {item.title}
                </a>
              ))}
              <button className="bg-primary rounded-full py-2 md:py-2.5 lg:py-3 xl:py-3.5 px-4 md:px-5 xl:px-6 text-base lg:text-lg text-white transition-all duration-300 ease-in-out hover:shadow-[0px_0px_20px_1px] hover:shadow-primary ">
                Launch App
              </button>
            </div>
            <button className="md:hidden" onClick={toggleOpen}>
              <HamburgerIcon />
            </button>
          </nav>
        </div>
      </div>
      <div
        className={`fixed top-0 left-0 w-full h-full bg-black md:bg-transparent transform ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out z-50`}
      >
        <Sidebar toggleOpen={toggleOpen} />
      </div>
    </>
  );
}

export default Header;
