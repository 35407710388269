import React from "react";
import abotimg from "./../../assets/png/about.png";
import { NextArrowIcon } from "../../helper/icon";
import aboutLine from "./../../assets/png/about-line.png";
function AboutUs() {
  return (
    <>
      <div
        id="About_Us"
        className="max-w-[1500px] mx-auto px-4 sm:px-6 lg:px-8 xl:px-10 2xl:px-12"
      >
        <div className="my-10 md:my-14 lg:my-16 xl:mt-24 2xl:my-36">
          <div className="border border-[rgba(255,255,255,0.05)] bg-[linear-gradient(180deg,rgba(46,50,71,0.3)_0%,rgba(5,7,17,0)_100%)] p-1 lg:p-2 xl:p-2.5 rounded-xl">
            <div className="flex flex-col lg:flex-row justify-center items-center lg:items-stretch gap-5  border border-[rgba(255,255,255,0.05)] bg-[linear-gradient(180deg,rgba(46,50,71,0.3)_0%,rgba(5,7,17,0)_100%)]  rounded-xl">
              <div className="lg:min-w-[400px] xl:max-w-[600px]  xl:w-full   flex flex-col justify-between gap-5 px-4 relative z-20">
                <h2
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration="000"
                  className="text-3xl md:text-4xl lg:text-5xl xl:text-6xl onest leading-[120%] sm:leading-[135%] capitalize font-bold my-5 lg:my-7 xl:my-10 heading w-fit  xl:ml-5 mx-auto lg:mx-0 relative z-50"
                >
                  About Us
                </h2>
                <div className="absolute bottom-0 right-0   w-full  z-10 hidden lg:block ">
                  <img src={aboutLine} alt="img" className="w-full " />
                </div>
                <div
                  data-aos="fade-down"
                  data-aos-easing="linear"
                  data-aos-duration="500"
                  className="max-w-[180px] sm:max-w-[275px] relative z-40  "
                >
                  <img src={abotimg} alt="abotimg" className="w-full h-auto" />
                </div>
              </div>
              <div className="lg:max-w-[690px] ml-auto rounded-[0px_0px_10px_10px] lg:rounded-[0px_10px_10px_0px] bg-[rgba(27,30,44,0.40)] p-4 sm:p-5  xl:p-6 2xl:p-8 ">
                <p
                  className="text-base md:text-lg  leading-[160%] text-white opacity-90"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration="500"
                >
                  At Keybridge Finance, we are committed to building a
                  decentralized financial ecosystem that bridges the gap between
                  innovative blockchain technologies and global financial
                  markets. Our platform empowers users to stake, invest, and
                  participate in decentralized finance while ensuring top-tier
                  security, transparency, and efficiency.
                </p>
                <p
                  className="text-base md:text-lg  leading-[160%] text-white opacity-90 mt-3 md:mt-5 lg:mt-8 xl:mt-10"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration="1000"
                >
                  With a focus on scalability and accessibility, Keybridge
                  Finance offers cutting-edge staking solutions and a powerful
                  platform that fosters growth for both users and emerging
                  projects. Our vision is to provide a seamless, user-friendly
                  experience where participants can unlock the full potential of
                  DeFi while maintaining full control over their assets.
                </p>
                <button
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration="2000"
                  className="flex items-center gap-1.5 md:gap-2 xl:gap-2.5 bg-primary rounded-[10px]  py-2 md:py-2.5 lg:py-3 xl:py-3.5 px-4 md:px-5 xl:px-7  text-base lg:text-lg text-white transition-all duration-300 ease-in-out hover:shadow-[0px_0px_20px_1px] hover:shadow-primary border border-transparent mt-5 md:mt-7 xl:mt-10 "
                >
                  Read founder more <NextArrowIcon />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutUs;
