import React from "react";
import visionimg from "./../../assets/png/vision.png";
function Vision() {
  return (
    <>
      <div className="max-w-[1400px] mx-auto px-4 sm:px-6 lg:px-8 xl:px-10 2xl:px-12">
        <div className="py-10 md:py-14 lg:py-16 xl:pt-24">
          <div className="flex sm:flex-row flex-col gap-6 justify-between items-center">
            <div className="max-w-[680px]">
              <h2
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration="500"
                className="text-3xl md:text-4xl lg:text-5xl xl:text-6xl onest leading-[120%] sm:leading-[135%] capitalize font-bold my-3 lg:my-6   heading w-fit"
              >
                Vision
              </h2>{" "}
              <p
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration="1000"
                className="text-base md:text-lg  leading-[160%] text-[#C2BACB]"
              >
                Keybridge Finance aims to bridge traditional finance with
                decentralized finance, providing seamless and secure
                opportunities for users to earn through staking and yield
                farming. The platform will target both retail and institutional
                users, focusing on cross-border transactions and decentralized
                finance tools, while enabling interoperability across blockchain
                networks to ensure a scalable and efficient ecosystem for all
                users.
              </p>
              <button
                className="bg-transparent border border-[#7A49FF] rounded-full py-2 md:py-2.5 lg:py-3 xl:py-3.5 px-4 md:px-5 xl:px-6 2xl:px-8 2xl:py-4 text-base lg:text-lg text-[#C2BACB] transition-all duration-300 ease-in-out hover:shadow-[0px_0px_20px_1px] hover:shadow-primary xl:mr-4  hover:text-primary mt-4 md:mt-6 xl:mt-9"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration="2000"
              >
                Learn More
              </button>
            </div>
            <div
              className="max-w-[300px] sm:max-w-[492px]   "
              data-aos="fade-left"
              data-aos-easing="linear"
              data-aos-duration="2000"
            >
              <img src={visionimg} alt="vision img" className="w-full h-auto" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Vision;
